import React, {useEffect, useState} from 'react';

import OnMapInfoPanel from "./OnMapInfoPanel";
import Shape from "../../shapes/Shape";
import Locations from "../../locations/Locations";
import ShapeStyling from "../../shapes/ShapeStyling";
import ShapeToolbar from "../../shapes/ShapeToolbar";
import ShapePopup from "../../shapes/ShapePopup";
import ShapeNotesDisplay from "../../shapes/ShapeNotesDisplay";


export default function ShapeInfoPanel({id, data, title, locations, toggleUnion, panelMinimize = false, pinsVisible = false, onPinsVisibleChange, setCustomColor, selfDelete, onTitleChange, onNotesChange, onPanelMinimizeChange, onInitRefresh, onLayerToTop, onLayerToBottom, onToggleVisible, onSaveToFiles, onToggleClusterified, dataLastUpdated = null, isEditable = false}) {

    let baseUnion = true;
    if (data && data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.union === false) {
        baseUnion = false;
    }
    const [union, setUnion] = useState(baseUnion);
    const [editMode, setEditMode] = React.useState(false);
    const [notesEditMode, setNotesEditMode] = React.useState(false);
    const [panelMinimized, setPanelMinimized] = React.useState(panelMinimize === true);

    let baseNotes = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.notes ? data.geoJSON.properties.notes : null;
    const [notes, setNotes] = useState(baseNotes);

    const [tempTitle, setTempTitle] = React.useState(title ? title : '');


    const updateTitle = (e) => {
        setTempTitle(e.target.value);
    }


    const save = () => {
        onTitleChange(tempTitle);
        setEditMode(false);
    }


    const updateNotes = (newNotes) => {
        setNotes(newNotes);
    }


    const saveNotes = () => {
        onNotesChange(notes);
        setNotesEditMode(false);
    }


    useEffect(() => {
        let baseUnion = true;
        if (data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.union === false) {
            baseUnion = false;
        }
        setUnion(baseUnion);

        let baseNotes = data.geoJSON && data.geoJSON.properties && data.geoJSON.properties.notes ? data.geoJSON.properties.notes : null;
        setNotes(baseNotes);
    }, [data.geoJSON]);


    useEffect(() => {
        setTempTitle(title);
    }, [title]);


    const onMinimizePanel = (e) => {
        e.preventDefault();

        setEditMode(false);
        setPanelMinimized(!panelMinimized);
        onPanelMinimizeChange(!panelMinimized);
    }


    const onToggleUnion = (e) => {
        e.preventDefault();

        setUnion(!union);
        toggleUnion();
    }

    const onAddClick = (e, type) => {
        e.preventDefault();
    }

    if (!(data.active && locations.counts)) {return null;}
    return panelMinimized ? (
        <div className="btn-group-vertical">
            <button onClick={onMinimizePanel} onMouseOver={onMinimizePanel} type="button" className="btn btn-onmap panel-style-warning" aria-haspopup="true" aria-expanded="false">
                <i className={'ti ti-arrows-diagonal fs-4'}></i>
            </button>
        </div>
    ) : (<div className="d-flex flex-row align-items-start gap-2 shape-info-panel-container">
        <OnMapInfoPanel className={'style-warning map-info-panel' + (notesEditMode ? '' : ' short-form')}>
            {editMode ? (
                <div className="position-relative d-inline-block py-2 pe-5 w-50">
                    <input type="text" className="form-control map-info-control d-inline-block" value={tempTitle} onChange={updateTitle} onKeyDown={(e) => {
                        // check if enter key is pressed
                        if (e.keyCode === 13) {
                            save();
                        }
                    }} />
                    <button className="icon-button d-flex" onClick={save}><i className={'ti ti-device-floppy fs-4'}></i></button>
                </div>
            ) : (<div className="position-relative d-flex justify-content-between">
                <div className="position-relative d-inline-block pe-5">
                    <h3 className="h5 py-2 m-0 d-inline-block">{tempTitle}</h3>
                    <button className={'icon-button'+(!isEditable ? ' d-none' : '')} onClick={() => {
                        setEditMode(true)
                    }}><i className="ti ti-pencil fs-4"></i></button>
                </div>

                {/*<button onClick={onInitRefresh} className={'btn border-0 p-2 mx-1 d-flex align-items-center'+(!isEditable ? ' d-none' : '')}>
                    <i className={'ti ti ti-refresh-dot fs-4 '}></i>
                </button>*/}
            </div>)}

            {data.type !== 'Marker' ? (<>
            {(union === true) && (
                <div className="">Included Properties: {locations.counts.positive}</div>
            )}
            <div className="">Total Properties: {locations.counts.total}</div>
            {dataLastUpdated ? (
                <div className="fs-2">Last synced: {dataLastUpdated.toLocaleString()}.</div>
            ) : (
                <div className="fs-2">Not synced yet.</div>
            )}
            </>) : ''}

            {(data.type === ShapeStyling.TYPE_POINT && data.geoJSON) ? (<>
                <hr/>
                <div className="">Location: {data.geoJSON.geometry.coordinates.join(', ')}</div>
            </>) : null}

            <hr/>

            {notesEditMode ? (<>
                <ShapePopup initialJSON={notes} onSave={(json) => {updateNotes(json);}} />
                <button className={'btn btn-sm btn-dark mt-3 '+(notesEditMode ? '' : 'd-none')} onClick={saveNotes}>Save changes</button>
            </>) : (<>
                <ShapeNotesDisplay initialJSON={notes} />
            </>)}
        </OnMapInfoPanel>
        <OnMapInfoPanel className={'style-warning'}>
            <ShapeToolbar
                id={id}
                data={data}
                onToggleUnion={toggleUnion}
                onEditNotes={setNotesEditMode}
                onMinimizePanel={onMinimizePanel}
                isEditable={isEditable}

                onInitRefresh={onInitRefresh}
                onSaveToFiles={onSaveToFiles}

                onLayerToTop={onLayerToTop}
                onLayerToBottom={onLayerToBottom}
                onToggleVisible={onToggleVisible}

                pinsVisible={pinsVisible}
                onPinsVisibleChange={onPinsVisibleChange}

                onColorChange={setCustomColor}
                onToggleClusterified={(locations && locations.counts && locations.counts.total < 10000) ? onToggleClusterified : null}
                onRemove={selfDelete}
            />
        </OnMapInfoPanel>
    </div>
    );
}